import React, { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

/** 
 * Компонент Alert имеет два альтернативных варианта стиля — filled и outlined — которые вы можете установить с помощью variantсвойства. 
 * Свойство severity принимает четыре значения, представляющие различные состояния 
 * — success(по умолчанию), info, warning и error — с соответствующими комбинациями значков и цветов для каждого:
 * */

const AlertNotification = ({messageAlert, open, setOpen, severity}) => {
  // состояние для управления видимостью уведомления
 // const [open, setOpen] = useState(showAlert);
   
  return (
      <Snackbar open={open}>
        <Alert severity={severity} onClose={() => setOpen(false)} 
        //variant='outlined'
        >
          {messageAlert}
        </Alert>
      </Snackbar>  
  );
};

export default AlertNotification;