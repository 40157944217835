export const shortener = (string, start_len, end_len) => {
    string = String(string)
    const start_string = string.slice(0, start_len)

    const end_string = string.slice(string.length-end_len, string.length)

    if (string.length > start_len + end_len + 1){
        if (end_len > 0){
            return   start_string + '...' + end_string
        } else {
            return   start_string + '...'
        }
    } else {
        return string
    }
}

export const  formatTimeMsToClock = (seconds) => {
    // Переводим миллисекунды во временные единицы
    //const seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    // Вычисляем остаток времени после удаления дней, часов, минут
    const minutesLeft = minutes % 60;
    const hoursLeft = hours % 24;

    let timeString = "";

    // Строим строку в зависимости от того, сколько времени прошло
    timeString += days + ":";
    timeString += hoursLeft + ":";   
    timeString += minutesLeft + "";

    return timeString.trim();
}

export const  formatTimeMsToClock_2 = (milliseconds) => {
    // Переводим миллисекунды во временные единицы
    const seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    // Вычисляем остаток времени после удаления дней, часов, минут
    const minutesLeft = minutes % 60;
    const hoursLeft = hours % 24;

    let timeString = "";

    // Строим строку в зависимости от того, сколько времени прошло
    if (days > 0) {
        timeString += days + ":";
    }
    if (days > 0 || hoursLeft > 0) {
        timeString += hoursLeft + ":";
    }
    timeString += minutesLeft + "";

    return timeString.trim();
}

export const USDformat = (text_value)=>{
    //console.log(text_value)
    /** Форматирование цифр в суммах - пробел каждые три знака и 2 знака после запятой */
    const m_f_options = { useGrouping: true , minimumFractionDigits: 2, maximumFractionDigits: 2 };  
    return Number(text_value).toLocaleString('ru-RU', m_f_options)
  }
  

export const gweiToEth = (gwei) => {
    const eth = gwei / 1e18; // 1 ETH = 1e9 Gwei
    return parseFloat(eth.toFixed(4));
}

export const  formatTimestamp = (timestamp) =>  {
    //console.log(timestamp)
    const date = new Date(timestamp * 1000);
    //console.log(date)
    // Форматирование компонентов даты/времени, чтобы они всегда были в виде двух цифр
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() возвращает месяцы от 0 до 11
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    // Сборка итоговой строки даты и времени
    return `${year}.${month}.${day} ${hours}:${minutes}:${seconds}`;
}

export const numberShortener = (number, decimals) => {    
    // Замена запятой на точку для корректного преобразования строки в число
    const numAsString = String(number).toString().replace(',', '.'); 
    // Преобразование в число и округление до нужного количества десятичных знаков
    return parseFloat(Number(numAsString).toFixed(decimals));
}

export const walletShortener = (wallet) => {   
    let result = ''
    try {
        result =  `${wallet.slice(0, 5)}...${wallet.slice(39,42)}`;
    } catch (error) {
        
    } 
    return result
}

export function formatNumberFor3(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }