import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

const CONSOLE_LOG = Boolean(window.settings.REACT_APP_CONSOLE_LOG)

if (!CONSOLE_LOG) {
    console.log = ()=>{}
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

