import { useEffect, useState } from "react";
import { createWeb3Modal, defaultConfig, useWeb3ModalProvider, useWeb3ModalAccount, useWeb3Modal } from '@web3modal/ethers/react'
import { BrowserProvider, Contract, formatUnits, isAddress, ethers } from 'ethers'
import AlertNotification from "./UI/AlertNotification";

import { gweiToEth } from "./utils";
import abi_ERC721 from './abi.json';


function App() {

//console.log(abi_ERC721)
const settings = window.settings

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = settings.REACT_APP_WALLETCONNECT_ID
const REACT_APP_ERC721_ADDRESS = settings.REACT_APP_ERC721_ADDRESS
const expectedNetworkId = settings.REACT_APP_CHAIN_ID

console.log( REACT_APP_ERC721_ADDRESS)

// 2. Set chains
const mainnet = {
  chainId:    settings.REACT_APP_CHAIN_ID,
  name:       settings.REACT_APP_WALLETCONNECT_NETNAME,
  currency:   settings.REACT_APP_WALLETCONNECT_CURRENCY,
  explorerUrl:settings.REACT_APP_WALLETCONNECT_EXPLORER,
  rpcUrl:     settings.REACT_APP_WALLETCONNECT_RPC
}

// 3. Create modal
const metadata = {
  name:         settings.REACT_APP_WALLETCONNECT_MODAL_NAME,
  description:  settings.REACT_APP_WALLETCONNECT_MODAL_DESCRIPTION,
  url:          settings.REACT_APP_WALLETCONNECT_MODAL_URL,
  icons:       [settings.REACT_APP_WALLETCONNECT_MODAL_ICON]
}
createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [mainnet],
  projectId,
  themeVariables: {
  //  '--w3m-accent': '#b81f1f'
  }
})

// 4. Use modal hook
const { open } = useWeb3Modal()

const { address, chainId, isConnected } = useWeb3ModalAccount()
const { walletProvider } = useWeb3ModalProvider()

/** Функция переключения на правильную сеть */
const changeNetwork = async () =>{
   try {
     // Переключение на нужную сеть
     await window.ethereum.request({
         method: 'wallet_switchEthereumChain',
         params: [{ chainId: '0x' + Number(expectedNetworkId).toString(16) }] 
     });
   } catch (switchError) {
       if (switchError.code === 4902) { 
           // Сеть не добавлена в MetaMask, предложение добавить её
           try {
             if(true){   
               await window.ethereum.request({
                 method: "wallet_addEthereumChain", 
                 params: [{
                   chainId: '0x' + Number(expectedNetworkId).toString(16), 
                   chainName: settings.REACT_APP_WALLETCONNECT_NETNAME,
                   nativeCurrency: {
                       name: "Binance Coin",
                       symbol: settings.REACT_APP_WALLETCONNECT_CURRENCY,
                       decimals: 18
                   },
                   rpcUrls: [settings.REACT_APP_WALLETCONNECT_RPC],
                   blockExplorerUrls: [settings.REACT_APP_WALLETCONNECT_EXPLORER]
                   }]
                 }
             );}
           } catch (addError) {
             console.error('Ошибка при добавлении Binance Smart Chain:', addError);
           }
       }
   }
 }
 /** Создаем объекты контрактов если кошелек подключен*/ 
 let contract_ERC721
 let contract_ERC721_onlyread
 let contractsCreated = false
 //const [contractsCreated , setContractsCreated] = useState(false)
 const createContracts = async () => {
   try {
     const ethersProvider = new BrowserProvider(walletProvider)
     const signer = await ethersProvider.getSigner()
     contract_ERC721 = new Contract(REACT_APP_ERC721_ADDRESS, abi_ERC721, signer);
    // console.log('contract_ERC20', contract_ERC20) 
     return true;
   } catch (error) {
     console.log(error)
     return false;
   }
 }

 if (isConnected) { 
   contractsCreated = createContracts();   
  // setContractsCreated(_contractsCreated)   
 }
 const ethDecimals = 18;
 const [inputToken, setInputToken] = useState(1)
 const [publicSale, set_publicSale] = useState(false)
 const [publicPrice, set_publicPrice] = useState('20000000000000000')
 const [ETH_value, set_ETH_value] = useState('20000000000000000')
 const [totalSupply, set_totalSupply] = useState('0')
 const [maxPublicMinted, set_maxPublicMinted] = useState(5)
 const [minPublicMinted, set_minPublicMinted] = useState(1)

 const requests = async () =>{
   console.log('chainId, expectedNetworkId', chainId, expectedNetworkId )
   try {
      if (isConnected) {
         console.log(' кошелек подключен')         
         const getCurrentSumm = await contract_ERC721.getCurrentSumm(inputToken>0? String(inputToken) : '1')
         console.log('getCurrentSumm', getCurrentSumm)
         set_ETH_value(Number(getCurrentSumm).toLocaleString('fullwide', {useGrouping:false}))

         const _publicPrice = await contract_ERC721.getCurrentSumm('1')
         set_publicPrice(Number(_publicPrice))

         const _publicSale = await contract_ERC721.publicSale()
         set_publicSale(Boolean(_publicSale))      

         const _totalSupply = await contract_ERC721.totalSupply()
         console.log('_totalSupply', _totalSupply)
         set_totalSupply(Number(_totalSupply)) 

         const _publicMinted = await contract_ERC721.publicMinted(address)
         console.log('_publicMinted', _publicMinted)
         set_maxPublicMinted(5-Number(_publicMinted)) 
         if(Number(_publicMinted) == 5) {
            set_minPublicMinted(0)
         } else {
            set_minPublicMinted(1)
         }
 
         if (chainId == expectedNetworkId) {     
           console.log(' requests()')
         } else {   
           console.log(' неправильная сеть')
         }  
      } else {
         // Только для чтения без подключения кошелька 
         const rpcEndpoint = settings.REACT_APP_WALLETCONNECT_RPC
         // Создаём провайдер, используя JSON-RPC endpoint
         const readProvider = new ethers.JsonRpcProvider(rpcEndpoint);
         contract_ERC721_onlyread = new ethers.Contract(REACT_APP_ERC721_ADDRESS, abi_ERC721, readProvider);

         const getCurrentSumm = await contract_ERC721_onlyread.getCurrentSumm(String(inputToken))
         console.log('getCurrentSumm', getCurrentSumm)
         set_ETH_value(Number(getCurrentSumm).toLocaleString('fullwide', {useGrouping:false}))

         const _publicPrice = await contract_ERC721_onlyread.getCurrentSumm('1')
         set_publicPrice(Number(_publicPrice))

         const _publicSale = await contract_ERC721_onlyread.publicSale()
         set_publicSale(Boolean(_publicSale))  

         const _totalSupply = await contract_ERC721_onlyread.totalSupply()
         console.log('_totalSupply', _totalSupply)
         set_totalSupply(Number(_totalSupply)) 
      } 
   } catch (error) {
       console.log(error)
   }   
 }

 useEffect(()=>{ // Выполняем запросы к бч
   requests();
   const interval = setInterval( async ()=>{
     console.log(isConnected,  contractsCreated, chainId , expectedNetworkId)
     try {
         requests();  
     } catch (error) {
         console.log(error)
     } 
   }, 1500)
   return() => {
     clearInterval(interval);
   };
 },[address, isConnected, inputToken])

const plusToken = () =>{
   console.log('plusToken', inputToken)
   if (maxPublicMinted > inputToken && inputToken >= minPublicMinted) {
      setInputToken(inputToken + 1);
   } else if (inputToken < minPublicMinted) {
      setInputToken(minPublicMinted);
   } else if (maxPublicMinted  <= inputToken) {
      setInputToken(maxPublicMinted);
   }
}

const minusToken = () =>{
   if (maxPublicMinted >= inputToken && inputToken > minPublicMinted) {
      setInputToken(inputToken - 1);
   } else if (inputToken < minPublicMinted) {
      setInputToken(minPublicMinted);
   } else if (maxPublicMinted  < inputToken) {
      setInputToken(maxPublicMinted);
   }
}

useEffect(()=>{
   if (maxPublicMinted >= inputToken && inputToken > minPublicMinted) {
      setInputToken(inputToken);
   } else if (inputToken < minPublicMinted) {
      setInputToken(minPublicMinted);
   } else if (maxPublicMinted  < inputToken) {
      setInputToken(maxPublicMinted);
   }
},[inputToken, maxPublicMinted])

/** Для компонента уведомления AlertNotification*/
const [messageAlert, setMessageAlert] = useState('false');
const [msgOpen, setMsgOpen] = useState(false);
const [msgSeverity , setMsgSeverity] = useState("warning");

const Notification = async (_messageAlert, _msgSeverity) =>{
   setMessageAlert(_messageAlert)
   setMsgSeverity(_msgSeverity)
   setMsgOpen(true)
   setTimeout(()=>{
       setMsgOpen(false)
   }, 6000)
}

const mint = async () => {
   try {
     console.log('mint')
     const tx = await contract_ERC721.mint(
       inputToken,
       { 
            value: ETH_value, 
         //  gasLimit: _gaslimit, //  gasPrice: gasPrice,  //  gasPrice: ethers.utils.parseUnits(_gasprice, "gwei"),
       }
     );
     console.log('\x1b[32m%s\x1b[32m\x1b[0m','Stake tx send: '+ tx.hash)
     const receiptet = await tx.wait();
     console.log('\x1b[32m%s\x1b[32m\x1b[0m','Stake tx confirmed: '+ receiptet.transactionHash);
     Notification(`Your mint was successful!`, "success")
   } catch (error) {
     Notification(`Mint cancelled. To successfully mint you need to have enough ETH and not exceed the allowed mint limit.`, "error")
     console.log(error)
   }
 } 

const action =  () => {
   if (isConnected) {
     if (chainId == expectedNetworkId) {
       mint()
     } else { //предлагаем сменить сеть
     // console.log('await changeNetwork()')
    // console.log('await changeNetwork()', Number(expectedNetworkId).toString(16))  
      //Notification(`Please change your network!`, "error")  
      changeNetwork()
     // changeNetwork = async ()
     }
   } else {
    open()
   }    
 }
 
 const actionText =  () => {
   if (isConnected) {
     if (chainId == expectedNetworkId) {
       return 'Mint'
     } else {
       //предлагаем сменить сеть
       return 'Change Network'
      // await changeNetwork()
     }
   } else {
    return 'Connect Wallet'
   }    
 }

return (
<>
{msgOpen && <AlertNotification
   messageAlert = {messageAlert}
   open = {msgOpen}
   setOpen = {setMsgOpen}
   severity = {msgSeverity}
/>}
<div class="popup__left">
   <div class="popup__img"><img src="img/dog.png" alt/></div>
</div>
<div class="popup__right">
   <div class="popup__cont">
      <div class="popup__counter"><span>Count</span> {totalSupply} / 8888</div>
      <div class="popup__counter"><span>Public price </span> {gweiToEth(publicPrice)}ETH</div>
      <div class="popup__counter"><span>Total price</span> {gweiToEth(ETH_value)}ETH</div>
   </div>
   <div class="popup__quantity">
      <button class="popup__minus"
      onClick={event=>minusToken()}
      >-</button>
      <input 
      onChange={event=>setInputToken(event.target.value)}
      type="text" value={inputToken}
      />
      <button class="popup__plus"
       onClick={event=>plusToken()}
      >+</button>
   </div>
   <div class="popup__buttons">
      <button
      onClick={event=>action()}
      >{actionText()}</button>
   </div>
   <div class="popup__info"><i>We provides the following range of discounts for investors: 1 or 2 NFT units – cost 0.02ETH each; 3 units – 0.018ETH each; 4 units – 0.017ETH each; 5 units – 0.016ETH each.
      <br/>Each NFT minted comes with a free bonus of 100,000 PUGBC.</i>
   </div>
</div>
</>
  );
}

export default App;
